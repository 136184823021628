import { CCard, CCardBody, CCardGroup, CCardHeader, CCol, CContainer, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { get } from 'src/utils/apiManager'

const ChatScreen = () => {
  const { orderId } = useParams()
  const [chat, setChat] = useState([])

  const getData = () => {
    get(`/admin/chat/order/chat/${orderId}`).then((res) => {
      if (res && res.data && res.data.length > 0) {
        setChat([...res.data])
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <CContainer fluid>
      <CRow>
        <CCol>
          <CCardGroup>
            <CCard className="p-4">
              <CCardHeader>Chat for #{orderId}</CCardHeader>
              <CCardBody style={{ overflow: 'auto', maxHeight: '75vh' }}>
                {chat.length > 0 &&
                  chat.map((_chat) => (
                    <div key={_chat._id} style={{ margin: '20px 0px' }}>
                      <div style={{ fontSize: '10px', margin: '4px 0px' }}>{_chat.sender.role}</div>
                      {!_chat.attachment ? <div
                        style={{
                          backgroundColor: '#D3DC40',
                          padding: '10px 20px',
                          width: 'fit-content',
                          borderRadius: '10px',
                          margin: '4px 0px',
                        }}
                      >
                        {_chat.body}
                      </div> : <div
                        style={{
                          // backgroundColor: '#D3DC40',
                          // padding: '10px 20px',
                          width: 'fit-content',
                          borderRadius: '10px',
                          // margin: '4px 0px',
                        }}>
                        <img src={_chat.attachment} width={100} height={100} style={{ borderRadius: "10px" }} />
                      </div>}
                      <div style={{ fontSize: '10px', margin: '4px 0px' }}>
                        {new Date(_chat.createdAt).getDate()}/{new Date(_chat.createdAt).getMonth()}
                        /{new Date(_chat.createdAt).getFullYear()}
                      </div>
                    </div>
                  ))}
                {chat.length === 0 && <>No Chat found</>}
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default ChatScreen
