
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { post } from 'src/utils/apiManager'

const OrderComplete = ({ visible, setVisible, orderId }) => {
    const navigate = useNavigate()
    const handleReadyToDelivered = () => {
        post(`admin/placed-order/ready-to-delivered`, { orderId }).then((data) => {
            if (data.status) {
                toast.success(data.message)
            } else {
                toast.success(data.message)
            }
            navigate("/admin/checkout-orders")
        }
        )
    }
    return (
        <CModal
            alignment="center"
            visible={visible}
            onClose={() => setVisible(false)}
            aria-labelledby="VerticallyCenteredExample"
            className=' all-order-scan-modal'
        >
            <CModalBody>
                <div className='warning-content'>
                    <span className='img-con'>
                        <img src="/img/check.png" alt='warning_image' />
                    </span>
                    <h1>Order Successful</h1>
                    <span className="order-success-txt">
                        <p> Thank you so much for you order. </p>
                    </span>
                    <CButton onClick={() => handleReadyToDelivered()}>Check out</CButton>
                </div>
            </CModalBody>
        </CModal>
    )
}

export default OrderComplete